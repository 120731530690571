import { CloseCircleOutlined } from "@ant-design/icons";
import { Checkbox, Image, Select, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState, useEffect, useImperativeHandle } from "react";
import useApiRequest, { roomOptions } from "../../utils/helperFunctions";
import { v4 as uuidv4 } from "uuid";

interface PhotoDetailsProps {
  imageSrc: string;
  roomInfo?: any;
  asInfo?: string[];
  onClose?: () => void;
  onDescriptionChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  description?: string;
  moreDetail?: boolean;
  setAddtionalInfo?: any;
  onDetailsChange?: (details: {
    room: string | undefined;
    items: string[];
    services: string[];
    additionalItems: string;
    exrtraAdditionID: number[];
    exrtraAdditionChecks: any[];
    virtualStagingText?: string;
    addtional_services?: number[];
    renovation_price?: number[];
    selectedLikeIn?: any[];
    selectedDayToDusk?: any[];
  }) => void;
  cardNumber?: number;
  getAddServiceInfo?: any;
  ref?: any;
  setujjwal?: any;
  getExtraServices?: any;
  selected_renovation?: any;
  setRenovation?: any;
  handleSkychange?: any;
  skyID?: any;
}

type Service = {
  name: string;
  value: string;
};

const PhotoDetails: React.FC<PhotoDetailsProps> = ({
  imageSrc,
  onClose,
  onDescriptionChange,
  roomInfo,
  description,
  moreDetail = false,
  onDetailsChange,
  asInfo,
  setAddtionalInfo,
  cardNumber,
  setujjwal,
  getAddServiceInfo,
  getExtraServices,
  selected_renovation,
  setRenovation,
  handleSkychange,
  skyID,
}) => {
  const [selectedRoom, setSelectedRoom] = useState<string | undefined>(
    undefined
  );
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [exrtraAdditionChecks, setExrtraAdditionChecks] = useState<any[]>([]);
  const [exrtraAdditionID, setExrtraAdditionID] = useState<number[]>([]);

  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [dummyselectedServices, setDummySelectedServices] = useState<any[]>([]);
  const [additionalItems, setAdditionalItems] = useState<string>("");
  const [itemcheckbox, setCheckbox] = useState([]);
  const [serviceCheckboxOptions, setServiceCheckboxOptions] = useState<any>([]);
  const { apiRequest } = useApiRequest();
  const [additonalInfoByCard, setAddtionalInfoByCard] = useState<{
    [key: number]: { name: string; value: string }[];
  }>({});
  const [extraAddition, setExtraAddition] = useState<any>([]);
  const [textareaVisible, setTextareaVisible] = useState<boolean>();
  const [virtualStagingText, setVirtualStagingText] = useState<string>("");
  const [additionalIDs, setAdditionalIDs] = useState<number[]>([]);
  const [renovation_prices, setRenovationPrices] = useState<any>([]);
  const [likeIN, setLikeIN] = useState<any>([]);
  const [selectedLikeIn, setSelectedLikeIn] = useState<any>({
    floorplan: [],
    template_or: "",
    level: "",
  });

  const [duskEffect, setDuskEffect] = useState<any>([]);
  const [selectedDayToDusk, setSelectedDayToDusk] = useState<any>({
    effects: [],
    sky_replacement: "",
  });

  const [isSkyEffect, setIsSkyEffect] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({
    id: 0,
    name: "",
  });

  console.log({ cardNumber, skyID });

  const items = [
    {
      id: 1,
      src: "https://www.signfix.com.au/wp-content/uploads/2017/09/placeholder-600x400.png",
      name: "Let our Experts decide",
    },
    {
      id: 2,
      src: "https://www.signfix.com.au/wp-content/uploads/2017/09/placeholder-600x400.png",
      name: "Bold hues without clouds",
    },
    {
      id: 3,
      src: "https://www.signfix.com.au/wp-content/uploads/2017/09/placeholder-600x400.png",
      name: "Bold Sky with clouds",
    },
    {
      id: 4,
      src: "https://www.signfix.com.au/wp-content/uploads/2017/09/placeholder-600x400.png",
      name: "Subtle hues with clouds",
    },
    {
      id: 5,
      src: "https://www.signfix.com.au/wp-content/uploads/2017/09/placeholder-600x400.png",
      name: "Calm blue with a hint of color",
    },
    {
      id: 6,
      src: "https://www.signfix.com.au/wp-content/uploads/2017/09/placeholder-600x400.png",
      name: "Subtle hues",
    },
  ];

  useEffect(() => {
    const currentPath = window.location.pathname;

    if (asInfo) {
      const asInfoMap = asInfo
        .filter((e: any) => {
          // Only filter out "virtual staging" if the URL is NOT "/services/virtual-renovation-form"
          return (
            currentPath === "/services/virtual-renovation-form" ||
            e.as_name.toLowerCase() !== "virtual staging"
          );
        })
        .map((e: any) => ({
          label: `${e.as_name} - ${parseFloat(e.as_price).toFixed(2)} $`,
          value: e.as_price, // Use the unique ID as value
          key: e.as_id,
          only_name: e.as_name,
        }));

      setServiceCheckboxOptions(asInfoMap);
    }
  }, [asInfo]);

  useEffect(() => {
    if (onDetailsChange) {
      onDetailsChange({
        room: selectedRoom,
        items: selectedItems || [],
        services: selectedServices,
        additionalItems,
        exrtraAdditionID,
        exrtraAdditionChecks,
        virtualStagingText: virtualStagingText,
        addtional_services: additionalIDs,
        renovation_price: renovation_prices,
        selectedLikeIn,
        selectedDayToDusk,
      });
    }
  }, [
    selectedRoom,
    selectedItems,
    selectedServices,
    additionalItems,
    exrtraAdditionChecks,
    exrtraAdditionID,
    virtualStagingText,
    selectedLikeIn,
    selectedDayToDusk,
  ]);

  const styles: { [key: string]: React.CSSProperties } = {
    container: {
      display: "flex",
      padding: "20px",
      border: "1px solid #ccc",
      borderRadius: "8px",
      maxWidth: `${moreDetail ? "100%" : "900px"}`,
      width: "100%",
      margin: "10px 0",
      boxShadow: "0 0 10px rgba(0,0,0,0.1)",
      alignItems: "center",
    },
    imgBox: {
      minWidth: "280px",
      minHeight: "160px",
      maxWidth: "350px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    imageColumn: {
      position: "relative",
      flex: "1",
      marginRight: "20px",
      maxWidth: "350px",
      width: "100%",
    },
    image: {
      width: "100%",
      height: "auto",
      borderRadius: "8px",
    },
    closeButton: {
      position: "absolute",
      top: "10px",
      right: "10px",
      background: "transparent",
      border: "none",
      fontSize: "24px",
      cursor: "pointer",
    },
    detailsColumn: {
      flex: "2",
      display: "flex",
      flexDirection: "column",
    },
    textHeader: {
      fontSize: "16px",
      marginBottom: "10px",
    },
    textArea: {
      flex: "1",
      padding: "10px",
      fontSize: "14px",
      border: "1px solid #ccc",
      borderRadius: "8px",
      resize: "none",
      minHeight: "200px",
    },
  };

  const handleRoomChange = (value: string) => {
    if (value) {
      const selectedObj = roomInfo?.find((r: any) => r.id === value);
      if (selectedObj) {
        const selectedData = selectedObj?.items?.map((e: any) => ({
          id: e.id,
          name: e.name,
        }));
        setCheckbox(selectedData);
      }
    }
    setSelectedRoom(value);
  };

  const handleItemsChange = (checkedValues: string[]) => {
    setSelectedItems(checkedValues);
  };

  const handleServiceCheckboxChange = (
    checked: boolean,
    value: number,
    name: string,
    key: string,
    index: number | undefined,
    id: string,
    hide: boolean,
    only_name: string
  ) => {
    const obj = { name, value, key, id, hide, only_name };
    console.log("handleServiceCheckboxChange", obj);
    if (checked) {
      console.log(obj);

      if (obj.only_name === "Virtual Staging") {
        setTextareaVisible(true);
      }

      if (obj?.hide === true) {
        // Add the ID to the state
        getExtraServices(obj.id);
        setExrtraAdditionChecks((prev: any) => [...prev, id]);
        setExrtraAdditionID((prev: any) => [...prev, key]);
        setRenovationPrices((prev: any) => [...prev, value]);
      } else if (obj?.hide === false) {
        setAdditionalIDs((prev: any) => [...prev, key]);
      }

      // Update other states
      setSelectedServices((prevSelectedServices) => [
        ...prevSelectedServices,
        key || id,
      ]);

      setDummySelectedServices((prev) => [...prev, obj]);

      setAddtionalInfo((prev: any) => [...prev, obj]);
    } else {
      if (obj.only_name === "Virtual Staging") {
        setTextareaVisible(false);
      }

      // Remove the ID from the state
      setExrtraAdditionChecks((prev: any) =>
        prev.filter((serviceId: any) => serviceId !== id)
      );

      setRenovation((prev: any) =>
        prev.filter((serviceId: any) => serviceId !== id)
      );

      setExrtraAdditionID((prev: any) =>
        prev.filter((serviceId: any) => serviceId !== id)
      );

      setRenovationPrices((prev: any) =>
        prev.filter((price: any) => price !== value)
      );

      // Update other states
      setSelectedServices((prevSelectedServices) =>
        prevSelectedServices.filter((serviceKey) => serviceKey !== key)
      );
      setDummySelectedServices((prev) =>
        prev.filter((service) => service.id !== id)
      );
      setAddtionalInfo((prev: any) =>
        prev.filter((service: any) => service.id !== id)
      );
    }
  };

  const handleAdditionalItemsChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setAdditionalItems(e.target.value);
  };

  const handleRemoveServicesByCard = () => {
    setAddtionalInfo((prev: any[]) => {
      // Filter out services that belong to the current card number
      return prev.filter(
        (service) =>
          !additonalInfoByCard[cardNumber || 0]?.some(
            (s) => s.name === service.name
          )
      );
    });

    setExrtraAdditionChecks((prev: any[]) => {
      // Filter out services that belong to the current card number
      return prev.filter(
        (service) =>
          !exrtraAdditionChecks[cardNumber || 0]?.some(
            (s: any) => s.id === service.id
          )
      );
    });

    const updatedRenovation = selected_renovation?.filter((id: any) => {
      const parts = id.split("_"); // Split the ID into parts
      return parts[1] !== cardNumber; // Keep only IDs where the card number doesn't match
    });

    setRenovation(updatedRenovation);

    // Optionally, clear the additional services for this card in `additonalInfoByCard`
    setAddtionalInfoByCard((prev) => {
      const updated = { ...prev };
      delete updated[cardNumber || 0];
      return updated;
    });
  };

  const handleImageDelete = () => {
    handleRemoveServicesByCard();
    setujjwal(dummyselectedServices);
    if (onClose) onClose();
  };

  const extraAdditionalService = async () => {
    // services-by-parent?parent_service_name=Commercial_Renovation
    // services-by-parent?parent_service_name=Residential_Virtual_Renovation
    // get-name-types/?name=floorplan
    // get-name-types/?name=Day_to_Dusk
    let url =
      "services-by-parent?parent_service_name=Residential_Virtual_Renovation";

    if (
      window.location.pathname === "/services/commercial/virtual-staging-form"
    ) {
      url = "services-by-parent?parent_service_name=Commercial Renovation";
    }

    const resp = await apiRequest({
      url: url,
      method: "GET",
      payload: {},
      showMessage: false,
      Authentication: false,
    });

    if (resp?.status === 200) {
      setExtraAddition(resp?.data);
    }
  };

  useEffect(() => {
    extraAdditionalService();
  }, []);

  const handleVirtualDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setVirtualStagingText(e.target.value);
  };

  const fetchEffects = async (value: string) => {
    try {
      const response = await apiRequest({
        url: `/get-name-types/${value}`,
        method: "GET",
        showMessage: false,
      });

      console.log("response ==>> ", response);
      if (value === "?name=floorplan") {
        setLikeIN(response?.data);
      } else {
        setDuskEffect(response?.data);
      }
    } catch (error) {
      console.error("Error fetching room options:", error);
    }
  };

  useEffect(() => {
    if (window.location.pathname === "/services/floor-plan-form") {
      fetchEffects("?name=floorplan");
    } else if (window.location.pathname === "/services/day-to-dusk-form") {
      fetchEffects("?name=Day_to_Dusk");
    }
  }, []);

  // const handleLikeInChange = (
  //   id: number,
  //   isChecked: boolean,
  //   cardNumber: any
  // ) => {
  //   setSelectedLikeIn((prev: any) =>
  //     isChecked ? [...prev, id] : prev.filter((item: any) => item !== id)
  //   );
  // };

  // Handle checkbox changes
  const handleLikeInChange = (id: number, isChecked: boolean) => {
    setSelectedLikeIn((prev: any) => ({
      ...prev,
      floorplan: isChecked
        ? [...prev.floorplan, id]
        : prev.floorplan.filter((item: number) => item !== id),
    }));
  };

  // Handle template orientation dropdown
  const handleTemplateChange = (value: string) => {
    setSelectedLikeIn((prev: any) => ({
      ...prev,
      template_or: value,
    }));
  };

  // Handle story/level dropdown
  const handleLevelChange = (value: string) => {
    setSelectedLikeIn((prev: any) => ({
      ...prev,
      level: value,
    }));
  };

  const handleDuskChange = (id: number, isChecked: boolean) => {
    if (id === 13) {
      setIsSkyEffect(isChecked);
    }
    setSelectedDayToDusk((prev: any) => ({
      ...prev,
      effects: isChecked
        ? [...prev.effects, id]
        : prev.effects.filter((item: number) => item !== id),
    }));
  };

  const forSky = (checked:any, uid:any) => {
    console.log(uid);

    const filtered = skyID?.filter(
      (item: any) => !item.includes(`_${cardNumber}`)
    );
    
    if(checked){
      return [...filtered, uid];
    } else {
      return filtered.filter((item:any) => item !== uid);
    }
  };

  const handleCheckboxChange = (
    id: number,
    name: string,
    uid: string,
    checked: any
  ) => {
    console.log(id, name, uid, checked, cardNumber);

    handleSkychange(forSky(checked, uid))

    setSelected(
      (prevSelected: any) =>
        prevSelected.id === id
          ? { id: 0, name: "" } // Deselect if already selected
          : { id, name } // Select the new item
    );

    setSelectedDayToDusk((prev: any) => ({
      ...prev,
      sky_replacement: name,
    }));
  };

  console.log("handleLikeInChange ===>>>>", selectedDayToDusk);

  return (
    <>
      <div style={styles.container}>
        <div style={styles.imageColumn}>
          <div style={styles.imgBox}>
            {imageSrc ? (
              <Image src={imageSrc} alt="Preview" style={styles.image} />
            ) : (
              <Spin />
            )}
          </div>
          <button
            onClick={() => handleImageDelete()}
            style={styles.closeButton}
          >
            <CloseCircleOutlined color="white" />
          </button>
        </div>

        {!moreDetail && (
          <>
            {window.location.pathname === "/services/floor-plan-form" ? (
              <div className="d-block">
                <h3 style={{ fontSize: "14px" }}>
                  What would you like in the floorplan?
                </h3>
                <div className="likein_checkbox">
                  {likeIN?.map((item: any) => (
                    <Checkbox
                      key={item.id}
                      onChange={(e) =>
                        handleLikeInChange(item.id, e.target.checked)
                      }
                      checked={selectedLikeIn.floorplan.includes(item.id)}
                    >
                      {item.name_types}
                    </Checkbox>
                  ))}
                </div>

                {/* Template Orientation Dropdown */}
                <div className="mt-3">
                  <label className="d-block" htmlFor="template_orientation">
                    <h3 style={{ fontSize: "14px" }}>Template orientation</h3>
                  </label>

                  <Select
                    style={{ minWidth: "50%" }}
                    id="template_orientation"
                    showSearch
                    placeholder="Select a Orientation"
                    value={selectedLikeIn.template_or}
                    onChange={handleTemplateChange}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      { value: "Landscape", label: "Landscape" },
                      { value: "Potrait", label: "Potrait" },
                    ]}
                  />
                </div>

                {/* Story/Level Dropdown */}
                <div className="mt-3">
                  <label className="d-block" htmlFor="storylevel">
                    <h3 style={{ fontSize: "14px" }}>Story/Level</h3>
                  </label>
                  <Select
                    style={{ minWidth: "50%" }}
                    id="storylevel"
                    showSearch
                    placeholder="Select any one"
                    value={selectedLikeIn.level}
                    onChange={handleLevelChange}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      { value: "basement", label: "Basement" },
                      { value: "basement2", label: "Basement 2" },
                      { value: "ground", label: "Ground" },
                      { value: "one", label: "One" },
                      { value: "two", label: "Two" },
                      { value: "three", label: "Three" },
                      { value: "four", label: "Four" },
                      { value: "five", label: "Five" },
                      { value: "six", label: "Six" },
                      { value: "seven", label: "Seven" },
                      { value: "eight", label: "Eight" },
                    ]}
                  />
                </div>
              </div>
            ) : window.location.pathname === "/services/day-to-dusk-form" ? (
              <>
                <div>
                  <h3 className="mt-3" style={{ fontSize: "14px" }}>
                    Would you like any of below Day To Dusk effects?
                  </h3>
                  <div className="dusk_checkbox">
                    {duskEffect?.map((item: any) => (
                      <Checkbox
                        key={item.id}
                        onChange={(e) =>
                          handleDuskChange(item.id, e.target.checked)
                        }
                        checked={selectedDayToDusk?.effects.includes(item.id)}
                      >
                        {item.name_types}
                      </Checkbox>
                    ))}
                  </div>
                  {isSkyEffect && (
                    <ul className="sky-checkbox-list">
                      <h3 className="mt-4" style={{ fontSize: "14px" }}>
                        Select sky effect
                      </h3>
                      {items?.map((item) => (
                        <li key={item.id}>
                          <input
                            type="checkbox"
                            id={`myCheckbox${item.id}_${cardNumber}`}
                            checked={skyID?.includes(
                              item.id + "_" + cardNumber
                            )} // Check if the uid exists
                            onChange={(e) => {
                              const uid = `${item.id}_${cardNumber}`;
                              handleCheckboxChange(
                                item.id,
                                item.name,
                                uid,
                                e.target.checked
                              );
                            }}
                          />
                          <label htmlFor={`myCheckbox${item.id}_${cardNumber}`}>
                            <img src={item.src} alt={item.name} />
                            <span>{item.name}</span>
                          </label>
                        </li>
                      ))}
                    </ul>
                  )}
                  <div className="flex-fill">
                    <div className="only-desc" style={styles.textHeader}>
                      Additional Information (Optional)
                    </div>
                    <textarea
                      placeholder="Have something on your mind? Let us know..."
                      // style={styles.textArea}
                      // value={description}

                      className="w-100"
                      onChange={handleAdditionalItemsChange}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex-fill">
                  <div className="only-desc" style={styles.textHeader}>
                    Additional Information (Optional)
                  </div>
                  <textarea
                    placeholder="Have something on your mind? Let us know..."
                    style={styles.textArea}
                    // value={description}
                    className="w-100"
                    onChange={handleAdditionalItemsChange}
                  />
                </div>
              </>
            )}
          </>
        )}

        {moreDetail && (
          <>
            <div className="flex-fill d-flex">
              <div className="box-left p-3" style={{ width: "60%" }}>
                <div
                  className="moreDetail-box d-flex"
                  style={styles.textHeader}
                >
                  More Details (Optional)
                </div>
                <div className="dropdowns">
                  <div>
                    <b>
                      Room/Area<span style={{ color: "red" }}>*</span>
                    </b>
                  </div>
                  <Select
                    showSearch
                    placeholder="Select room/area"
                    filterOption={(input,option)=>{
                      if(option?.children){
                        return option?.children.toString().toLowerCase().includes(input);
                      }
                        return false
                    }}
                    style={{ width: "100%" }}
                    value={selectedRoom}
                    onChange={handleRoomChange}
                  >
                    {roomInfo?.map((room: any) => (
                      <Select.Option key={room.id} value={room.id}>
                        {room.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                {window.location.pathname !==
                  "/services/virtual-renovation-form" &&
                window.location.pathname !==
                  "/services/commercial/virtual-staging-form" ? (
                  <div className="item-checkbox pt-4">
                    <span>Would you like any of these items in your room?</span>
                    <span className="d-block mb-2">
                      ( Basic items will be added )
                    </span>
                    <div className="add-services-checkbox">
                      <Checkbox.Group
                        value={selectedItems}
                        onChange={handleItemsChange}
                      >
                        {itemcheckbox?.map((item: any) => (
                          <Checkbox
                            name={item?.name}
                            style={{ width: "49%" }}
                            key={item?.id}
                            value={item?.id}
                          >
                            {item?.name}
                          </Checkbox>
                        ))}
                      </Checkbox.Group>
                    </div>
                  </div>
                ) : (
                  <div className="item-checkbox extra_addition pt-4">
                    <div className="add-services-checkbox">
                      <p>
                        <b>
                          <u>Choose the renovation services you like to add.</u>
                        </b>
                      </p>
                      {/* <Checkbox.Group
                        value={exrtraAdditionChecks}
                        onChange={handleExtraItemsChange}
                      > */}
                      <div className="additional_checkboxes_box">
                        {extraAddition?.map((item: any) => (
                          <Checkbox
                            key={item.id} // Use `item.id` as the key, assuming it is unique
                            id={item.id} // Use the service ID directly
                            name={item.service_name}
                            checked={selected_renovation?.includes(
                              item.id + "_" + cardNumber
                            )} // Check against `item.id`
                            onChange={(e) => {
                              const checked = e.target.checked;
                              handleServiceCheckboxChange(
                                checked,
                                Number(item.price),
                                `${item.service_name} - ${item.price} $`,
                                item.id, // Use `item.id` as the key
                                undefined, // Index is no longer needed
                                item.id + "_" + cardNumber, // Use `item.id` for consistency
                                true, // Hide the service ID from the card number
                                item?.only_name
                              );
                            }}
                          >
                            {`${item.service_name} - ${item.price} $`}
                          </Checkbox>
                        ))}
                      </div>

                      {/* </Checkbox.Group> */}
                    </div>
                  </div>
                )}
                <div className="mt-3">
                  <span>Not mentioned? Type them in here</span>
                  <TextArea
                    placeholder="Study table, dresser, recliner, carpets. etc."
                    value={additionalItems}
                    onChange={handleAdditionalItemsChange}
                  />
                </div>
              </div>
              <div className="box-right p-3" style={{ width: "40%" }}>
                <p>
                  <b>Additional services</b>
                </p>
                <div>
                  {serviceCheckboxOptions.map((service: any, index: number) => {
                    return (
                      <div key={service.key}>
                        <Checkbox
                          key={uuidv4()} // Unique key for each Checkbox component
                          id={`${cardNumber}-${service.key}-${index}`} // Make the ID more unique using service.key
                          name={service?.label}
                          checked={selectedServices.includes(service.key)}
                          onChange={(e) => {
                            const abc = `${cardNumber}-${index}`; // Unique identifier based on cardNumber, service.key, and index

                            handleServiceCheckboxChange(
                              e.target.checked,
                              Number(service.value),
                              service?.label,
                              service?.key,
                              index,
                              abc,
                              false,
                              service?.only_name
                            );
                          }}
                        >
                          {service.label}
                        </Checkbox>
                      </div>
                    );
                  })}
                </div>
                {textareaVisible && (
                  <div className="mt-4">
                    <span style={{ fontWeight: "500" }}>
                      Enter details for Virtual Staging :
                    </span>
                    <TextArea
                      rows={7}
                      onChange={handleVirtualDescriptionChange}
                      value={virtualStagingText}
                      id={`textarea-${cardNumber}`}
                      placeholder={`Enter details for Virtual Staging`}
                      style={{
                        display: "block",
                        marginTop: "10px",
                        width: "100%",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PhotoDetails;
