import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Row,
  Col,
  Checkbox,
  Modal,
  CheckboxProps,
  message,
  Flex,
} from "antd";
import { ArrowRightOutlined, UploadOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./orderform.css";
import PhotoDetails from "./PhotoDetails";
import StepComponent from "../Sections/Steps";
import GallerySlider from "../Slider/GallerySlider";
import useApiRequest, {
  designCardsData,
  threeDesignCard,
} from "../../utils/helperFunctions";
import Paypal from "../Pages/MakePayment";
import TermsCon from "../Pages/TermsCon";

const { Item } = Form;

type CommercialStagingFormProps = {
  unitPrice?: number;
  form_name?: string;
};

type AnyObject = Record<string, any>;

const CommercialStagingForm: React.FC<CommercialStagingFormProps> = (props) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    fileList: [] as any[],
    descriptions: [] as string[],
    selectedStyle: "",
    details: [] as any[], // Add this state to track details of each photo
    additionalService: [] as any[],
  });

  const [current, setCurrent] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const { apiRequest } = useApiRequest();
  const [roomResp, setRoomResp] = useState([]);
  const [asInfo, setAsInfo] = useState([]);
  const [addtionalInfo, setAddtionalInfo] = useState([]);
  const [pricePerPhoto, setPricePerPhoto] = useState(8);
  const [paymentModalSwitch, setPaymentModalSwitch] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [orderInfo, setOrderInfo] = useState({
    ID: 0,
    totalPrice: 0.0,
  });
  const [endpoint, setEndpoint] = useState<string>("");
  const [urlKey, setUrlKey] = useState<any>("");
  const [ujjwal, setujjwal] = useState([]);
  const [agreeTerms, setagreeTerms] = useState(false);
  const [extraAdditionService, setExtraAdditionService] = useState<any>([]);
  const [showDesignCard, setShowDesignCard] = useState<boolean>(false);
  const [addtionalKeyword, setAddtionalKeyword] = useState<string>("");
  const [skyID, setSkyID] = useState<any>([]);
  const [termsModalVisible, setTermsModalVisible] = useState(false);

  const yogendraFunc = (data: any) => {
    setujjwal(data);
  };

  const handleSkychange = (data: any) => {
    setSkyID(data);

  }

  const getTheKey = () => {
    // Extract the `key` from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const key = queryParams.get("key");
    setUrlKey(key);
  };

  useEffect(() => {
    // Get the current URL
    const currentUrl = window.location.pathname;

    // Extract the last segment of the URL
    const lastSegment = currentUrl.substring(currentUrl.lastIndexOf("/") + 1);

    setEndpoint(lastSegment);
  }, []);

  const getUnitPrice = async () => {
    try {
      const resp = await apiRequest({
        url: "service-price",
        method: "POST",
        showMessage: false,
        payload: { name: urlKey },
        Authentication: true,
      });

      if (resp.status === 200) {
        setPricePerPhoto(resp?.data?.price);
      } else {
        setPricePerPhoto(8);
        message.error("Failed to fetch unit price. Please Reload");
      }
    } catch (errors) {
      console.error("Failed to fetch unit price", errors);
    }
  };

  useEffect(() => {
    if (urlKey?.length === 0) {
      getTheKey();
    } else {
      getUnitPrice();
    }
  }, [urlKey]);

  const [sliderImages, setSliderImages] = useState<string[]>([]);

  const steps = [
    {
      title: "Personal Info",
    },
    {
      title: "Select style",
    },
    {
      title: "Upload photos",
    },
    {
      title: "Photo details",
    },
    {
      title: "Add extra",
    },
  ];

  useEffect(() => {
    updateStep();
  }, [formData]);

  useEffect(() => {
    const fetchInitialData = async () => {
      await Promise.all([getRoomId()]);
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    calculateTotalPrice();
  }, [formData.fileList]);

  const getRoomId = async () => {
    try {
      const response = await apiRequest({
        url: "/rooms",
        method: "GET",
        showMessage: false,
      });
      if (response.status === 200) {
        setRoomResp(response.data);
      }
    } catch (error) {
      console.error("Error fetching room options:", error);
    }
  };

  const getASservices = async () => {
    try {
      if (addtionalKeyword.length > 0) {
        const response = await apiRequest({
          url: `/AdditionalServices${addtionalKeyword}`,
          method: "GET",
          showMessage: false,
        });
        if (response.status === 200) {
          setAsInfo(response?.data);
        }
      }
    } catch (error) {
      console.error("Error fetching room options:", error);
    }
  };

  const updateStep = () => {
    if (formData.name && formData.email && formData.phone) {
      if (formData.selectedStyle) {
        if (formData.fileList.length > 0) {
          if (formData.details.length === formData.fileList.length) {
            setCurrent(4); // Step 5: Add extra
          } else {
            setCurrent(3); // Step 4: Photo details
          }
        } else {
          setCurrent(2); // Step 3: Upload photos
        }
      } else {
        setCurrent(1); // Step 2: Select style
      }
    } else {
      setCurrent(0); // Step 1: Personal Info
    }
  };

  const calculateTotalPrice = () => {
    const newTotalPrice = formData.fileList.length * pricePerPhoto;
    setTotalPrice(newTotalPrice);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePhoneChange = (value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      phone: value,
    }));
  };

  const handleUploadChange = (fileList: any) => {
    setFormData((prevData) => ({
      ...prevData,
      fileList: fileList.fileList,
    }));
  };

  useEffect(() => {
    if (ujjwal?.length > 0) {
      const newAds: any = getFilteredArray(ujjwal, addtionalInfo);
      setAddtionalInfo(newAds);
    }
  }, [ujjwal]);

  // const handleClose = (index: number) => () => {
  //   console.log(formData?.details)
  //   // debugger
  //   console.log({index})
  //   setFormData((prevData) => {
  //     const newFileList = prevData.fileList.slice();
  //     newFileList.splice(index, 1);
  //     const newDescriptions = prevData.descriptions.slice();
  //     newDescriptions.splice(index, 1);

  //     const newDetails = prevData.details.slice();

  //     newDetails.splice(index, 1);

  //     console.log({newDetails});

  //     const removedDetails: any = newDetails?.splice(index, 1);
  //     setAddtionalInfo((prevAddInfo) =>
  //       prevAddInfo.filter(
  //         (service: any) =>
  //           !removedDetails[0]?.services?.some(
  //             (s: string) => s === service.count
  //           )
  //       )
  //     );

  //     return {
  //       ...prevData,
  //       fileList: newFileList,
  //       descriptions: newDescriptions,
  //       details: newDetails,
  //     };
  //   });
  // };

  const handleClose = (index: number) => () => {
    console.log(formData?.details);
    console.log({ index });

    setFormData((prevData) => {
      const newFileList = prevData.fileList.slice();
      newFileList.splice(index, 1);

      const newDescriptions = prevData.descriptions.slice();
      newDescriptions.splice(index, 1);

      const newDetails = prevData.details.slice(); // Copy details array
      const removedDetails = newDetails.splice(index, 1); // Remove the object at index

      console.log({ newDetails }); // Logs the updated details array (should now have 2 objects)

      setAddtionalInfo((prevAddInfo) =>
        prevAddInfo.filter(
          (service: any) =>
            !removedDetails[0]?.services?.some(
              (s: string) => s === service.count
            )
        )
      );

      return {
        ...prevData,
        fileList: newFileList,
        descriptions: newDescriptions,
        details: newDetails, // Use updated newDetails array
      };
    });
  };

  const handleDescriptionChange =
    (index: number) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = e.target;
      setFormData((prevData) => {
        const newDescriptions = [...prevData.descriptions];
        newDescriptions[index] = value;
        return {
          ...prevData,
          descriptions: newDescriptions,
        };
      });
    };

  const onCheckboxChange: CheckboxProps["onChange"] = (e) => {
    setagreeTerms(e.target.checked);
  };

  const sanitizePayload = (data: AnyObject): AnyObject => {
    const sanitizedObject: AnyObject = {};

    Object.entries(data).forEach(([key, value]) => {
      if (value && typeof value === "object" && !Array.isArray(value)) {
        // Recursively sanitize nested objects
        const sanitizedValue = sanitizePayload(value);
        if (Object.keys(sanitizedValue).length > 0) {
          sanitizedObject[key] = sanitizedValue;
        }
      } else if (Array.isArray(value)) {
        // Filter out empty or invalid elements in arrays
        const sanitizedArray = value
          .map((item) =>
            typeof item === "object" ? sanitizePayload(item) : item
          )
          .filter((item) => item !== null && item !== undefined && item !== "");
        if (sanitizedArray.length > 0) {
          sanitizedObject[key] = sanitizedArray;
        }
      } else if (value !== null && value !== undefined && value !== "") {
        // Include valid scalar values
        sanitizedObject[key] = value;
      }
    });

    return sanitizedObject;
  };

  // console.log("orderInfoooooooooooooooooo", orderInfo);

  const handleSubmit = async () => {

    // Validate that every image has a room_id
    const missingRoomIdIndex = formData.fileList.findIndex(
      (file, index) => !formData.details[index]?.room
    );

    if (missingRoomIdIndex !== -1 && !showDesignCard) {
      // If a missing room_id is found, show an error message
      message.error(
        `Select Room/Area for Image number #${missingRoomIdIndex + 1}`
      );
      return; // Exit the function early
    }

    if (!agreeTerms) {
      message.error("Please agree to the terms and conditions");
      return;
    }

    if (formData.selectedStyle === "" && !showDesignCard) {
      message.error("Please select a furnishing style for this order.");
      return;
    }

    try {
      const payload = {
        user_details: {
          email: formData.email,
          phone: formData.phone,
          name: formData.name,
          team_id: "",
        },
        property_data: {
          order_name: props?.form_name || urlKey,
          order_type: "pr",
          final_amount: sum + totalPrice,
          order_ammount: sum + totalPrice,
          furnishing_style: formData.selectedStyle,
          images: formData.fileList.map((file, index) => ({
            url: file.url || file.response?.file_url, // Ensure the URL is set correctly
            room_id: formData.details[index]?.room || null,
            additional_room_items:
              formData.details[index]?.additionalItems || "",
            room_item_id: formData.details[index]?.items || [],
            additional_services:
              formData.details[index]?.addtional_services || [],
            service_id: formData.details[index]?.exrtraAdditionID || [],
            description: formData.descriptions[index] || "",
            virtual_staging_text:
              formData.details[index]?.virtualStagingText || "",
            story_level: formData.details[index]?.selectedLikeIn?.level || "",
            template_orientation:
              formData.details[index]?.selectedLikeIn?.template_or || "",
            like_in: formData.details[index]?.selectedLikeIn?.floorplan.length > 0 ? formData.details[index]?.selectedLikeIn?.floorplan : formData.details[index]?.selectedDayToDusk?.effects,
            sky_replacement: formData.details[index]?.selectedDayToDusk?.sky_replacement || "",
          })),
        },
      };

      const roomService = formData.fileList.findIndex(
        (file, index) =>
          !payload?.property_data.images[index]?.service_id?.length
      );

      if (window.location.pathname === "/services/virtual-renovation-form") {
        if (roomService !== -1) {
          // If a missing room_id is found, show an error message
          message.error(
            `Atleaset Select one Renovation service for Image number #${
              roomService + 1
            }`
          );
          return; // Exit the function early
        }
      }

      console.log("ppayyloadd", sanitizePayload(payload));

      const resp = await apiRequest({
        url: "OrderCreate",
        method: "POST",
        payload: sanitizePayload(payload),
        showMessage: false,
        Authentication: true,
      });

      setOrderInfo((prev) => {
        return {
          ...prev,
          orderId: resp?.data?.OrderId,
          totalPrice: sum + totalPrice,
        };
      });

      if (resp?.data.status === 200) {
        message.success(resp?.data?.message.toUpperCase());
        setPaymentModalSwitch((prev: boolean) => {
          return !prev;
        });
      } else {
        message.error("Failed to create order. Please try again.");
      }
    } catch (e) {
      console.error("Error ->", e);
    }
  };

  const handleDetailsChange =
    (index: number) =>
    (details: {
      room: string | undefined;
      items: string[];
      services: string[];
      additionalItems: string;
      exrtraAdditionID: number[];
      virtualStagingText?: string;
      addtional_services?: number[];
      renovation_price?: number[];
      exrtraAdditionChecks?: any;
      selectedLikeIn?: any[];
      selectedDayToDusk?: any[];
    }) => {
      setFormData((prevData) => {
        const newDetails = [...prevData.details];
        newDetails[index] = details;
        return {
          ...prevData,
          details: newDetails,
        };
      });
    };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList.map((file: any) => ({
      ...file,
      url: file.response?.url || file.url, // Ensure the URL is captured
    }));
  };

  const handleDesignCardClick = (name: string, images: any) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedStyle: name,
    }));
  };

  const handleModalImages = (images: any) => {
    setSliderImages(images);
    setModalVisible(true);
  };

  const handleUploadMedia = async (file: any) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      return await fetch(`${process.env.REACT_APP_API_URL}/upload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Adjust the token retrieval as needed
        },
        body: formData,
      })
        .then((response) => response.json())
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    } catch (e) {
      console.error("Error uploading media:", e);
    }
  };

  const isCommercialForm = () => {
    if (
      window.location.pathname === "/services/commercial/virtual-staging-form"
    ) {
      return threeDesignCard;
    }
    return designCardsData;
  };

  const sum = addtionalInfo.reduce((pv, cv: any) => pv + cv.value, 0);

  const getAggregatedServices = (services: any[]) => {
    // console.log("getAggregatedServices", services);

    const serviceMap: Record<string, { count: number; total: number }> = {};

    services.forEach((service) => {
      const [name, price] = (service.name || "").split(" - ");
      const parsedPrice = price?.includes("$")
        ? parseFloat(price.replace("$", "").trim())
        : parseFloat(price?.trim() || "0");

      if (!parsedPrice || isNaN(parsedPrice)) return; // Skip if price parsing fails

      if (serviceMap[name]) {
        serviceMap[name].count += 1;
        serviceMap[name].total += parsedPrice;
      } else {
        serviceMap[name] = { count: 1, total: parsedPrice };
      }
    });

    return Object.entries(serviceMap)
      .filter(([name]) => {
        // Find services where at least one `hide: true` exists, exclude those in the result
        const originalService = services.find(
          (service) => service.name.startsWith(name) && service.hide
        );
        return !originalService;
      })
      .map(([name, data]) => ({
        name,
        count: data.count,
        total: data.total.toFixed(2), // Format total to two decimal places
      }));
  };

  function getFilteredArray(data1: any[], data2: any[]) {
    // Extract all the IDs from data1 into a Set for faster lookup
    const idsToRemove = new Set(data1.map((item: any) => item.id));
    // Filter out objects in data2 whose id is found in the idsToRemove Set
    return data2.filter((item: any) => !idsToRemove.has(item.id));
  }

  function getExtraServices(data: any) {
    setExtraAdditionService((prev: any) => [...prev, data]);
  }

  console.log({ extraAdditionService });

  const calculateRenovationPrice = (data: any) => {
    return data?.reduce((total: any, item: any) => {
      const renovationSum =
        item?.renovation_price?.reduce(
          (sum: any, price: any) => sum + price,
          0
        ) || 0;
      return total + renovationSum;
    }, 0);
  };

  function doesPathnameMatch(pathnames: string[]): boolean {
    const currentPath = window.location.pathname; // Get the current pathname
    return pathnames.some((pathname) => currentPath.includes(pathname)); // Check if any pathname matches
  }

  const getAdditionalKeyword = () => {
    let additionalKeyword = "";

    if (window.location.pathname === "/services/floor-plan-form") {
      additionalKeyword = "?floorplan=true";
    } else if (
      window.location.pathname === "/services/virtual-renovation-form"
    ) {
      additionalKeyword = "?virtual_renovation=true";
    } else if (window.location.pathname === "/services/virtual-staging-form") {
      additionalKeyword = "?virtual_staging=true";
    } else if (
      window.location.pathname === "/services/commercial/virtual-staging-form"
    ) {
      additionalKeyword = "?virtual_staging=true";
    } else if (
      window.location.pathname ===
      "/services/commercial/virtual-renovation-form"
    ) {
      additionalKeyword = "?virtual_renovation=true";
    }

    if (additionalKeyword.length > 0) {
      setAddtionalKeyword(String(additionalKeyword));
    }
  };

  useEffect(() => {
    getAdditionalKeyword();

    const pathnamesToCheck = [
      "/services/floor-plan-form",
      "/services/day-to-dusk-form",
      "/services/object-removal-form",
      "/services/business-card-form",
      "/services/vector-design-form",
      "/services/digital-display-form",
      "/services/image-enhancement-form",
      "/services/occupied-vacant-form",
    ];
    setShowDesignCard(doesPathnameMatch(pathnamesToCheck));
  }, [window.location.pathname]);

  useEffect(() => {
    getASservices();
  }, [addtionalKeyword]);

  // const onChange = (value: string) => {
  //   console.log(`selected ${value}`);
  // };

  return (
    <>
      <div className="steps-container">
        <StepComponent current={current} steps={steps} />
      </div>
      <div className="order-form-container hp-container">
        <h2 className="mb-4 pt-4">Start by telling us about yourself</h2>
        <Form layout="vertical" onFinish={handleSubmit}>
          <Row gutter={10}>
            <Col sm={24} md={24} lg={12} xl={12}>
              <Item
                label="Your name"
                name="name"
                rules={[{ required: true, message: "Please enter your name." }]}
              >
                <Input
                  placeholder="For example; John Smith"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </Item>
            </Col>

            <Col sm={24} md={24} lg={12} xl={12}>
              <Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please input your Email." },
                  {
                    type: "email",
                    message: "The input is not a valid E-mail.",
                  },
                ]}
              >
                <Input
                  type="email"
                  placeholder="For example; johnsmith98@gmail.com"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </Item>
            </Col>

            <Col sm={24} md={24} lg={12} xl={12}>
              <Item
                label="Phone number"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter your phone number.",
                  },
                ]}
              >
                <PhoneInput
                  country={"in"}
                  value={formData.phone}
                  enableLongNumbers
                  onChange={handlePhoneChange}
                  inputStyle={{ width: "100%" }}
                />
              </Item>
            </Col>
          </Row>

          {/* <div>
            <h3 style={{ fontSize: "14px" }}>Let's redraw your floorplan</h3>
            <Select
              showSearch
              placeholder="Select a person"
              optionFilterProp="label"
              onChange={onChange}
              options={[
                {
                  value: "2D Floor Plan (With Furniture)",
                  label: "2D Floor Plan (With Furniture)",
                },
                {
                  value: "2D Floor Plan (Without Furniture)",
                  label: "2D Floor Plan (Without Furniture)",
                },
              ]}
            />
          </div> */}

          {!showDesignCard ? (
            <>
              <hr />
              <h5>Pick a furnishing style for your property*</h5>
              <div className="row pt-4">
                {isCommercialForm()?.map((design, index) => (
                  <div
                    className={`col-md-2 col-sm-4 ${
                      design.name === formData.selectedStyle && "selected-style"
                    }`}
                    key={index}
                  >
                    <div
                      className={`design-card text-center p-2 ${
                        formData.selectedStyle === design.name ? "selected" : ""
                      }`}
                      onClick={() =>
                        handleDesignCardClick(design.name, design.images)
                      }
                    >
                      <div className="design-image">
                        <img
                          src={design.thumbnail}
                          alt="Design Image"
                          className="w-100"
                        />
                      </div>
                      <div className="design-text">
                        <span>{design.name}</span>
                      </div>
                      <div
                        className="see-examplebtn"
                        style={{
                          color: "navy",
                          cursor: "pointer",
                          fontSize: "12px",
                        }}
                        onClick={() => handleModalImages(design.images)}
                      >
                        view example
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            ""
          )}

          <hr />
          <Item
            label="Upload some photos here*"
            name="upload"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: "Please upload some photos." }]}
          >
            <Upload
              listType="picture"
              beforeUpload={(file)=>{
                console.log(file)
                const isImage = file.type.startsWith('image/');
                if(!isImage){
                  message.error("Please Upload Only Image files.");
                }
                console.log(Upload.LIST_IGNORE)
                return isImage || Upload.LIST_IGNORE;
              }}
              fileList={formData.fileList}
              accept="image/*"
              onChange={handleUploadChange}
              // beforeUpload={() => false} // Prevent automatic upload
              multiple
              customRequest={({ file, onSuccess, onError }) => {
                handleUploadMedia(file).then(onSuccess).catch(onError);
              }}
            >
              <Button icon={<UploadOutlined />}>Upload More Photos</Button>
            </Upload>
          </Item>

          {formData?.fileList.length > 0 && (
            <>
              <hr />

              <b>Add Details about your photos.</b>

              <div className="image-detail pt-4">
                {formData.fileList.map((file, index) => {
                  const uid = file.uid;
                  console.log({uid})
                  return (
                    <PhotoDetails
                      asInfo={asInfo}
                      roomInfo={roomResp}
                      key={file.uid}
                      imageSrc={file?.response?.file_url}
                      onClose={handleClose(index)}
                      moreDetail={showDesignCard ? false : true}
                      onDetailsChange={handleDetailsChange(index)}
                      onDescriptionChange={handleDescriptionChange(index)}
                      description={formData.descriptions[index] || ""}
                      setAddtionalInfo={setAddtionalInfo}
                      cardNumber={file?.uid}
                      setujjwal={yogendraFunc}
                      getExtraServices={getExtraServices}
                      selected_renovation={extraAdditionService}
                      setRenovation={setExtraAdditionService}
                      handleSkychange={handleSkychange}
                      skyID={skyID}
                    />
                  );
                })}
              </div>
            </>
          )}
          <hr />
          <div className="paymentBox">
            {/* <div
              className="coupon-container"
              style={{ visibility: "hidden", opacity: 0 }}
            >
              <label htmlFor="coupon" className="coupon-label">
                Enter a coupon code
              </label>
              <div className="coupon-input-container">
                <input
                  type="text"
                  id="coupon"
                  className="coupon-input"
                  placeholder="FOR EXAMPLE; HURRY100"
                />
                <button className="apply-button">APPLY</button>
              </div>
            </div> */}

            <div>
              <div className="terms-and-makePayment mt-4">
                <Checkbox required onChange={onCheckboxChange}>
                  I agree to{" "}
                  <span onClick={() => setTermsModalVisible(!termsModalVisible)} style={{ color: "blue", cursor: 'pointer' }}>Terms & Conditions*</span>
                </Checkbox>
              </div>
              <button type="submit" className="theme-btn mt-3">
                <Flex gap={10}>
                  <div>Make Payment</div>
                  <div>
                    <ArrowRightOutlined className="right_icon" />
                  </div>
                </Flex>
              </button>
            </div>

            <div className="order-summary-container">
              <div className="order-summary">
                <h2>ORDER SUMMARY</h2>
                <div className="order-item w-100">
                  {formData?.fileList?.length > 0 ? (
                    <span className="w-100">
                      {addtionalInfo.length > 0 && <b>Additional Services</b>}
                      {addtionalInfo.length > 0 && (
                        <hr style={{ marginBlock: "5px" }} />
                      )}
                      {addtionalInfo.length > 0 &&
                        getAggregatedServices(addtionalInfo).map((service) => (
                          <div
                            className="d-flex justify-content-between"
                            key={service.name}
                          >
                            <span>
                              {service.name} for {service.count}{" "}
                              {service.count === 1 ? "image" : "images"}
                            </span>
                            <b>${service.total}</b>
                          </div>
                        ))}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                {window.location.pathname ===
                  "/services/virtual-renovation-form" ||
                window.location.pathname ===
                  "/services/commercial/virtual-staging-form" ? (
                  <>
                    <Flex justify="space-between">
                      <span>
                        Price for {formData?.fileList.length}{" "}
                        {formData?.fileList.length === 1 ? "Image" : "Images"}{" "}
                      </span>
                      <b>${calculateRenovationPrice(formData?.details)}</b>
                    </Flex>
                  </>
                ) : (
                  <>
                    <Flex justify="space-between">
                      <span>
                        Price for {formData?.fileList.length}{" "}
                        {formData?.fileList.length === 1 ? "Image" : "Images"}{" "}
                      </span>
                      <b>${totalPrice.toFixed(2)}</b>
                    </Flex>
                  </>
                )}

                <hr />
                <div className="order-total mt-0">
                  <span>ORDER TOTAL</span>
                  <span>
                    {formData?.fileList.length > 0
                      ? `$${(sum + totalPrice).toFixed(2)}`
                      : "0.00"}
                  </span>
                </div>
              </div>
              <div className="payment-secured">
                <span>PAYMENTS SECURED BY</span>
                <div className="payment-icons">
                  <img
                    src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/checkout-logo-large.png"
                    alt="PayPal"
                  />
                  <img
                    src="https://w7.pngwing.com/pngs/32/363/png-transparent-visa-master-card-and-american-express-mastercard-payment-visa-credit-card-emv-credit-card-visa-and-master-card-background-text-display-advertising-logo.png"
                    alt="Cards are accepted"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="terms-and-makePayment mt-4">
            <Checkbox onChange={onCheckboxChange}>
              I agree to <a href="#">terms & conditions*</a>
            </Checkbox>
          </div>
          <button type="submit" className="theme-btn mt-3">
            <Flex gap={10}>
              <div>Make Payment</div>
              <div>
                <ArrowRightOutlined className="right_icon" />
              </div>
            </Flex>
          </button> */}
        </Form>
      </div>

      <Modal
        centered
        width="810px"
        onCancel={() => setPaymentModalSwitch(false)}
        footer={false}
        open={paymentModalSwitch}
        className="payment-modal"
      >
        <Paypal details={formData} info={orderInfo} />
      </Modal>

      <Modal
        title="Design Gallery"
        width="800px"
        open={modalVisible}
        footer={null}
        onCancel={() => setModalVisible(false)}
      >
        <GallerySlider images={sliderImages} />
      </Modal>

      <Modal
        centered
        width="810px"
        onCancel={() => setTermsModalVisible(false)}
        footer={false}
        open={termsModalVisible}
        className="terms-modal"
      >
        <TermsCon />
      </Modal>
    </>
  );
};

export default CommercialStagingForm;
